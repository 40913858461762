@import 'lms-ui/theme.scss';

.buttonArrow {
  margin-left: 20px;
}

.buttonRedirect:focus {
  background: var(--color-chart-sevres-blue) !important;
  border: 1px solid var(--color-chart-sevres-blue) !important;
}

.ctaBox {
  width: 60%;
  padding: 20px 40px;
  font-size: 16px;
  line-height: 20px;

  @include mobile {
    margin: auto;
    height: 150px;
    padding: 0px 0px 20px 0px;
    width: 90%;
    font-size: 14px;
    line-height: 16px;
  }
}

.notFound {
  font-weight: 700;
  font-size: 80px;
  line-height: 70px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-variant-numeric: normal;
  font-feature-settings: normal;

  @include mobile {
    font-size: 40px;
    line-height: 30px;
  }
}

.context {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  @include mobile {
    font-size: 14px;
    line-height: 16px;
  }
}
